(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/storage/assets/javascripts/storage.js') >= 0) return;  svs.modules.push('/components/components/storage/assets/javascripts/storage.js');


'use strict';

svs = svs || {};

var VER = {
  RAW: 0, 
  COMPRESS: 1, 
  PARSE: 2 
};

svs.components = svs.components || {};
svs.components.Storage = svs.components.Storage || {};

svs.components.Storage.browser = (function() {
  var tested = { testrun: false, passed: false };
  var log = new svs.core.log.Logger('svs.components.Storage');

  function doneAsync(callback, data, err) {
    if (typeof callback === 'function') {
      setTimeout(function() {
        callback(data, err);
      }, 0);
    } else {
      return data;
    }
  }
  function getKey(key, domain) {
    return domain ? domain + '.' + key : key;
  }

  function get(key, domain, callback) {
    if (!test(key, domain)) {
      return doneAsync(callback);
    }

    var _key = getKey(key, domain);
    var result = getRaw(_key);
    if (result) {
      if (!hasExpired(_key, result)) {
        result = result.data;
      } else {
        result = undefined;
      }
    }
    return doneAsync(callback, result);
  }

  function getRaw(key) {
    var session; var storageItem;
    try {
      session = sessionStorage.getItem(key);
      storageItem = session ? session : localStorage.getItem(key);
    } catch (e) {
      log.info('Read error: ' + e);
      return undefined;
    }

    if (typeof storageItem !== 'string') {
      return undefined;
    }
    try {
      storageItem = JSON.parse(storageItem);

      if (storageItem.v & VER.COMPRESS) {
        storageItem.data = LZString.decompressFromUTF16(storageItem.data);
      }

      if (storageItem.v & VER.PARSE) {
        storageItem.data = JSON.parse(storageItem.data);
      }

      return storageItem;
    } catch (e) {
      return storageItem || undefined;
    }
  }

  function test(key, domain) {
    if (tested.testrun) {
      return tested.passed;
    }
    var cleanupQuota;
    var errorMsg = '';
    var mod = 'testsvsstorage';
    var quota;
    tested.testrun = true;

    try {
      if (!localStorage || !sessionStorage) {
        return false;
      }
    } catch (error) {
      if (error.name && error.name === 'SecurityError') {
        log.warn('Storage disabled, ' + error.message);
      }
      return false;
    }

    try {
      localStorage.setItem(mod, mod);
      localStorage.removeItem(mod);
      var clean = localStorage.getItem('cleanup');
      if (clean) {
        var diff = new Date() - new Date(parseInt(clean, 10));
        if (parseInt(diff / (24 * 3600 * 1000 * 7), 10) >= 1) {
          cleanUpAsync();
          localStorage.setItem('cleanup', Date.now());
        }
      } else {
        localStorage.setItem('cleanup', Date.now());
      }
    } catch (e) {
      try {
        if (e.name === 'QuotaExceededError' && localStorage.length === 0) {
          tested.passed = false;
          return false;
        }

        errorMsg = e.message + ' : ' + e.name;
        quota = getQuota();
        cleanUp();
        cleanupQuota = getQuota();
        errorMsg += ' Quota before: ' + quota + ', after: ' + cleanupQuota;
        localStorage.setItem(mod, mod);
        localStorage.removeItem(mod);
        log.info(errorMsg);
        return true;
      } catch (err) {
        log.warn('Localstorage failed,' +
          key +
          ' : ' +
          domain +
          ' e:' +
          err.message +
          ' e2:' +
          errorMsg);
        return false;
      }
    }
    tested.passed = true;
    return true;
  }

  function cleanUp() {
    var result;
    var keys = Object.keys(localStorage);

    keys.forEach(function(key) {
      result = getRaw(key);
      hasExpired(key, result);
    });
  }
  function cleanUpAsync() {
    var result;
    var keys = Object.keys(localStorage);

    keys.forEach(function(key) {
      setTimeout(function() {
        result = getRaw(key);
        hasExpired(key, result);
      }, 0);
    });
  }

  function getQuota(domain) {
    var size = 0;

    [localStorage, sessionStorage].forEach(function(store) {
      var keys = Object.keys(store);
      keys.forEach(function(key) {
        if (store[key] && (!domain || key.indexOf(domain + '.') === 0)) {
          size += store[key].length;
        }
      });
    });

    return size * 2;
  }

  function set(key, domain, payload, expires, callback) {
    if (!test(key, domain)) {
      return doneAsync(callback);
    }
    setTimeout(function() {
      var expiration;
      var value;
      var toClass = {}.toString;
      var errorMsg = '';
      var cleanupQuota;
      var quota;
      var storeItem;
      var _key = getKey(key, domain);

      if (expires) {
        if (toClass.call(expires) === '[object Number]') {
          expiration = new Date().getTime() + expires * 60000;
        }
        if (toClass.call(expires) === '[object Date]') {
          expiration = expires.getTime();
        }
        if (!expiration) {
          if (typeof callback === 'function') {
            return callback(new Error('InvalidFormat'));
          }
          throw new Error('InvalidFormat');
        }

        storeItem = { exp: expiration, v: VER.RAW, data: undefined };

        if (payload && typeof payload === 'object') {
          payload = JSON.stringify(payload);
          storeItem.v = VER.PARSE;
        }

        if (
          payload &&
          typeof payload === 'string' &&
          payload.length > 256
        ) {
          storeItem.v += VER.COMPRESS;
          payload = LZString.compressToUTF16(payload);
          if (payload.length > 65536) {
            log.warn('Object not saved. Exceeds 64 kb! ' +
              _key +
              ': ' +
              Math.floor(payload.length / 1024));
            if (typeof callback === 'function') {
              callback();
            }
            return;
          }
        }

        storeItem.data = payload;

        value = JSON.stringify(storeItem);

        try {
          removeRaw(_key);
          localStorage.setItem(_key, value);
        } catch (e) {
          try {
            errorMsg = e.message + ' : ' + e.name;
            quota = getQuota();
            cleanUp();
            cleanupQuota = getQuota();
            errorMsg +=
              ' Quota before: ' + quota + ', after: ' + cleanupQuota;
            localStorage.setItem(_key, value);
            log.info('Save after clean up ' + errorMsg);
          } catch (err) {
            log.warn('Failed to save, ' +
              key +
              ', ' +
              domain +
              ' err:' +
              err.message +
              ':' +
              err.name +
              ':' +
              errorMsg);
          }
        }
      } else {
        value = JSON.stringify({ data: payload });
        try {
          removeRaw(_key);
          sessionStorage.setItem(_key, value);
        } catch (e) {
          errorMsg = e.message + ' : ' + e.name;
          quota = getQuota();
          cleanUp();
          cleanupQuota = getQuota();
          errorMsg +=
            ' Quota before: ' + quota + ', after: ' + cleanupQuota;
          try {
            sessionStorage.setItem(_key, value);
            log.info('Sessionstore save after clean up ' + errorMsg);
          } catch (err) {
            log.warn('Failed to save sessionstore, ' +
              key +
              ', ' +
              domain +
              ' err:' +
              err.message +
              ':' +
              err.name +
              ':' +
              errorMsg);
          }
        }
      }

      if (typeof callback === 'function') {
        callback();
      }
    }, 0);
  }

  function hasExpired(key, data) {
    if (data && data.exp) {
      if (data.exp < new Date().getTime()) {
        removeRaw(key);
        return true;
      }
    }
    return false;
  }

  function remove(key, domain, callback) {
    var _key = getKey(key, domain);

    if (!test(key, domain)) {
      return doneAsync(callback);
    }
    return doneAsync(function(key) {
      removeRaw(key);
      if (typeof callback === 'function') {
        callback();
      }
    }, _key);
  }

  function removeRaw(key) {
    sessionStorage.removeItem(key);
    localStorage.removeItem(key);
  }

  function reset() {
    tested = { testrun: false, passed: false };
  }

  return {
    get: get,
    set: set,
    remove: remove,
    getQuota: getQuota,
    cleanUp: cleanUp,
    reset: reset
  };
})();



 })(window);