(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/storage/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/components/components/storage/assets/javascripts/constants.js');

'use strict';

svs = svs || {};
svs.components = svs.components || {};
svs.components.Storage = svs.components.Storage || {};
svs.components.Storage.constants = {
  EXPIRES_10M: 10,
  EXPIRES_15M: 15,
  EXPIRES_1H: 60,
  EXPIRES_24H: 1440,
  EXPIRES_48H: 2880,
  EXPIRES_1WEEK: 10080,
  EXPIRES_1MONTH: 43200,
  EXPIRES_1YEAR: 525600
};



 })(window);